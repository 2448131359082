import { Component } from "react";
import logo from "../logon.png";

class HeaderView extends Component {

    downloadApp() {
        window.open("https://cdn.echoecho.cn/app-release-1.0.0.apk");
    }

    
    render() {
        return (
            <div class="header">
                    <div class="header-center">
                        <img src={logo} class="logo"></img>

                        <span class="tab"><a href="/">首页</a></span>
                        <span class="tab"><a href="/#about">关于 “如果但是”</a></span>
                        <span class="tab"><a href="/contact">联系我们</a></span>

                        <div class="download" onClick={() => { this.downloadApp(); }}>
                            <span>下载 APP</span>
                        </div>
                    </div>

                </div>
        );
    }
}

export { HeaderView };