import { Component } from "react";
import { withRouter } from "react-router";
import "./main.css";
import "./main_view.css";
import create from "../img/create.jpg"
import option from "../img/option.jpg"
import result from "../img/result.jpg"
import stat from "../img/stat.jpg"



class IndexBody extends Component {

    state = {
        showSelected: false,
        selectYes: false,
        yesRate: "63",
        noRate: "37"
    }

    buildBottomButton() {
        const { yesRate, noRate, showSelected, selectYes } = this.state;
        if (showSelected) {
            if (selectYes) {
                return (
                    <div class="n option-buttons">
                        <div class="option-button-result yes">
                            <p class="same-rate">{yesRate + "% 选择 YES"}</p>
                            <p class="not-same-rate">{noRate + "% 选择 NO"}</p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div class="n option-buttons">
                        <div class="option-button-result no">
                            <p class="same-rate">{noRate + "% 选择 NO"}</p>
                            <p class="not-same-rate">{yesRate + "% 选择 YES"}</p>
                        </div>
                    </div>
                );
            }
            
        } else {
            return (
                <div class="n option-buttons">
                    <div class="option-button yes" onClick={() => {
                        this.selectOption(true);
                    }}>
                        YES
                    </div>
                    <div class="option-button no" onClick={() => {
                        this.selectOption(false);
                    }}>
                        NO
                    </div>
                </div>
            );
        }
    }

    buildDownloadHint() {
        if (this.state.showSelected) {
            return (
                <div class="download-hint">
                    <span onClick={() => { this.downloadApp(); }}>
                        下载 APP，查看更多脑洞问题
                    </span>
                </div>
            )
        } else {
            return (
                <div class="download-hint">
                </div>
            )
        }
    }

    selectOption(o) {
        this.setState({
            showSelected: true,
            selectYes: o
        })
    }

    downloadApp() {
        window.open("https://cdn.echoecho.cn/app-release-1.0.0.apk");
    }


    render() {
        return (
            <div class="body">
                    <div class="content-card">
                        <p class="card-title">如果</p>
                        <p class="card-content">你可以读取别人的思想</p>
                    </div>
                    
                    <div class="content-card n">
                        <p class="card-title">但是</p>
                        <p class="card-content">同时别人也可以读取你的思想</p>
                    </div>

                    {
                        this.buildBottomButton()
                    }

                    {
                        this.buildDownloadHint()
                    }

                    <div class="section-item" id="about">
                        <div class="text-section">
                            <h3>海量有趣脑洞问答</h3>
                            <p>500+ 经典脑洞问答</p>
                            <p>甄选用户创建的海量问答</p>
                        </div>
                        <div class="image-section right">
                            <img src={option} class="intro-img"></img>
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image-section">
                            <img src={result} class="intro-img"></img>
                        </div>
                        <div class="text-section">
                            <h3>看看其他人都是怎么选的</h3>
                            <p>查看用户选择的百分比</p>
                            <p>觉得不错，可以点赞哦</p>
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="text-section">
                            <h3>发挥脑洞，创建你的问题</h3>
                            <p>有不错的想法？创建你的问题吧</p>
                            <p>内容要合法哦</p>
                        </div>
                        <div class="image-section right">
                            <img src={create} class="intro-img"></img>
                        </div>
                    </div>
                    <div class="section-item">
                        <div class="image-section">
                            <img src={stat} class="intro-img"></img>
                        </div>
                        <div class="text-section">
                            <h3>看看你的问题如何被选择的</h3>
                            <p>审核通过后，可实时查看用户选择情况</p>
                        </div>
                    </div>
                </div>
        )
    }
}

IndexBody = withRouter(IndexBody);
export { IndexBody };