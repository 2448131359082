import { Component } from "react";
import {
    withRouter,
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import "./main.css";
import { BottomView } from "./bottom";
import { HeaderView } from "./header";
import { IndexBody } from "./main_view";
import { PrivacyView } from "./privacy_view";
import { UserAuthView } from "./user_auth_view";
import { ContactView } from "./contact_view";


class MainView extends Component {

    render() {
        return(
            <div class="main">
                <HeaderView></HeaderView>

                <Router>
                    <Switch>
                        <Route exact path="/" component={IndexBody}></Route>
                        <Route exact path="/privacy" component={PrivacyView}></Route>
                        <Route exact path="/auth" component={UserAuthView}></Route>
                        <Route exact path="/contact" component={ContactView}></Route>
                    </Switch>
                </Router>

                <BottomView></BottomView>
            </div>
        )
    }
}


MainView = withRouter(MainView)
export { MainView }