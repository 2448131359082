import { Component } from "react";
import { withRouter } from "react-router";
import "./contact.css"


class ContactView extends Component {
    render() {
        return (
            <div class="body">
                <div class="contact">
                    <p>您在 app 使用过程中可以通过以下方式联系我们</p>
                    <p>邮箱：julitec@163.com（推荐）</p>
                    <p>电话：+8615268824667</p>
                    <p>地址：浙江省杭州市萧山区宁围街道民和路479号国泰科技大厦801室</p>
                </div>
            </div>
        )
    }
}

ContactView = withRouter(ContactView);
export {ContactView};