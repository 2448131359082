import { Component } from "react";
import { withRouter } from "react-router";
import "./user_auth.css"


class UserAuthView extends Component {
    render() {
        return (
            <div class="body">
            <div class="user-auth">
<h3>用户协议</h3>
<p>重要须知：</p>
<p>本协议是您（个人或单一实体）与本应用的开发者之间关于开发者向您提供本应用所涉相关
服务的法律协议。在此特别提醒，用户（您）欲使用相关服务，必须事先认真阅读本服务条
款中各条款，包括免除或者限制开发者责任的免责条款及对用户的权利限制。请您审阅并接
受或不接受本服务条款（未成年人审阅时应得到法定监护人的陪同）。本服务条款一旦发生
变更, 将在网页上公布修改内容。修改后的服务条款一旦在网页上公布即有效代替原来的服
务条款。您可随时登陆网页查阅最新版服务条款。您若不同意对服务条款的修改，您应不使
用或主动取消相关服务，否则，您的任何对相关服务的登陆、查看等使用行为将被视为您对
相关修改和本协议的理解和接受。
如果您选择接受本协议开通服务，即表示您同意接受协议各项条件的约束。如果您不同意本
服务条款，则不能获得使用本服务的权利。您若有违反本条款的任何规定，开发者有权随时
中止或终止您服务的使用资格并保留追究相关法律责任的权利</p>
<p>* 如无特别说明，下列术语在本协议中的含义为：</p>
<p>开发者：指如果但是app 作者。</p>
<p>应用：如果但是app。</p>

<p>1. 本应用及服务由开发者开发，受国际版权条约和其他知识产权法及条约的保护。</p>
<p>2.用户在遵守法律及本条款的前提下可依本协议使用应用及享受服务，但用户无权实施包括但不限于下列行为：</p>
<p>2.1 删除应用及其他副本上所有关于版权的信息、内容；</p>
<p>2.2 对应用进行反向工程、反向汇编、反向编译等；</p>
<p>2.3 对于应用中相关信息等，未经开发者书面同意，用户不得擅自实施包括但不限于下列行为：使用、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助“软件”发展与之有关的衍生产品、作品等。</p>
<p>2.4 利用应用及服务发表、传送、传播、储存危害国家安全、祖国统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容。</p>
<p>2.5 利用发表、传送、传播、储存侵害他人知识产权、商业机密权等合法权利的内容。</p>
<p>2.6 进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐户；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其它实施破坏网络安全的行为； 企图干涉、破坏本“软件”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造 TCP/IP 数据包名称或部分名称。</p>
<p>3. 用户须明白，使用本服务产品涉及到互联网服务，可能会受到各个环节不稳定因素的影响。因此服务存在不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。</p>
<p>4. 用户须明白，在使用本服务产品存在有来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）的匿名或冒名的信息的风险，用户须承担以上风险，开发者对服务不作任何类型的担保，不论是明确的或隐含的，包括所有有关信息真实性、适商性、适于某一特定用途、所有权和非侵权性的默示担保和条件 ，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害 ，不承担任何责任。</p>
<p>5．使用本服务必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本条款，对于用户违法或违反本协议的使用(包括但不限于言论发表、传送等)而引起的一切责任，由用户负全部责任。</p>
<p>6．尊重用户个人隐私信息的私有性是本应用及服务的原则，开发者将会采取合理的措施保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或用户同意等原因外，未经用户同意不向第三方公开、透露用户个人隐私信息。</p>
<p>7. 特别提醒用户，使用互联网必须遵守国家有关的政策和法律，如刑法、国家安全法、保密法、计算机信息系统安全保护条例等，保护国家利益，保护国家安全，对于违法使用互联网络而引起的一切责任，由用户负全部责任。</p>
<p>7.1. 用户不得使用本应用及服务发送或传播任何妨碍社会治安或非法、虚假、骚扰性、侮辱性、恐吓性、伤害性、破坏性、挑衅性、淫秽色情性等内容的信息。</p>
<p>7.2. 用户不得使用本应用及服务发送或传播敏感信息和违反国家法律制度的信息。</p>
<p>7.3. 本应用及服务同大多数因特网产品一样，易受到各种安全问题的困扰，包括但不限于：</p>
<p>1）透露详细个人资料，被不法分子利用，造成现实生活中的骚扰。</p>
<p>2）哄骗、破译密码。</p>
<p>3）下载安装的其它软件中含有“特洛伊木马”等病毒，威胁到个人计算计上信息和数据的安全，继而威胁对本应用及服务的使用。</p>
<p>8. 请用户注意勿在使用本应用及服务中透露自己的各类财产帐户、银行卡、信用卡、QQ 号码及对应密码等重要资料，否则由此带来的任何损失由用户自行承担。</p>
<p>9. 用户应规范、合法地使用本应用及服务，如用户有在公共环境下捣乱、骚扰、欺骗其他用户等行为或者实施其他违反本协议的行为，一经发现，开发者有权停止服务。</p>
<p>10.用户须明白，开发者为了本应用整体运营的需要，开发者拥有随时修改或中断、中止或终止服务而不需通知您的权利，开发者行使前述权利不需对您或任何第三方负责或承担任何赔偿责任。</p>
            </div>
            </div>
        )
    }
}

UserAuthView = withRouter(UserAuthView)
export {UserAuthView};

