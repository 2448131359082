import { Component } from "react";
import "./bottom.css"

class BottomView extends Component {
    render() {
        return (
            <div class="footer">
                <p class="auth">© 2021 杭州桔里信息科技 All Right Reserved.</p>
                <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备17058033号-1</a>
                <a href="/auth" >用户协议</a>
                <a href="/privacy" >隐私政策</a>
                <a href="/contact">联系我们</a>
            </div>
        );
    }
}

export { BottomView }